import { getCurrentSite } from '../../../../common/utils';
import AudioNoResults from './AudioNoResults';
import GraphicNoResults from './GraphicNoResults';
import VideoNoResults from './VideoNoResults';

function factory() {
  switch (getCurrentSite()) {
    case 'graphicstock':
      return GraphicNoResults;

    case 'videoblocks':
      return VideoNoResults;

    case 'audioblocks':
      return AudioNoResults;

    default:
      return null;
  }
}

const NoResults = factory();

export default NoResults;

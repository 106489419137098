import { Tab } from '@headlessui/react';
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDown, Close } from '@videoblocks/react-icons';

import LoadingIndicator from '../../../common/components/LoadingIndicator';
import { StockItemContext } from '../../../common/types/StockItemTypes';
import useRelatedVideo, {
  RelatedVideoData,
  RelatedVideoDatum,
} from '../hooks/useRelatedVideo';
import SimpleSearchResults from './searchResults/SimpleSearchResults';

interface SameShootSameModelSimilarClipsDrawerProps {
  stockItemId: number;
  onClose(): void;
}

const SameShootSameModelSimilarClipsDrawer: FC<
  SameShootSameModelSimilarClipsDrawerProps
> = ({ stockItemId, onClose }) => {
  const { isInitialized, isErrored, data, loadMore } =
    useRelatedVideo(stockItemId);

  const [arrowPosition, setArrowPosition] = useState(0);
  useEffect(() => {
    const elementToPointTo = document.querySelector(
      `[data-stock-id="${stockItemId}"]`
    );
    if (elementToPointTo) {
      const boundingRect = elementToPointTo.getBoundingClientRect();
      // Position the arrow element at the midpoint of the target element,
      // but take 24px off to account for the width of the arrow itself and the spacing of our container.
      setArrowPosition(boundingRect.left + boundingRect.width / 2 - 24);
    }
  }, [stockItemId]);

  const tabs = useMemo<
    {
      id: keyof RelatedVideoData;
      label: string;
      data: RelatedVideoDatum;
    }[]
  >(
    () =>
      [
        data?.sameShoot?.results?.length > 0
          ? {
              id: 'sameShoot' as keyof RelatedVideoData,
              label: 'Same Shoot',
              data: data.sameShoot,
            }
          : null,
        data?.sameModel?.results?.length > 0
          ? {
              id: 'sameModel' as keyof RelatedVideoData,
              label: 'Same Model',
              data: data.sameModel,
            }
          : null,
        data?.similarItems?.results?.length > 0
          ? {
              id: 'similarItems' as keyof RelatedVideoData,
              label: 'Similar Clips',
              data: data.similarItems,
            }
          : null,
      ].filter(Boolean),
    [data]
  );

  return (
    <div className="searchDrawer w-full bg-transparent my-2.5 px-2 py-0 col-span-1 md:col-span-2 lg:col-span-3 3xl:col-span-4 4xl:col-span-5 relative">
      {/* Arrow element. */}
      {arrowPosition && (
        <div
          className="absolute w-6 h-6 bg-gray-110 transform rotate-45 -top-1.5"
          style={{ left: arrowPosition }}
        />
      )}

      <div className="bg-gray-110 rounded-lg p-6">
        <div className="bg-gray-110 content clear-both text-white relative flex-col">
          {/* Close button. */}
          <button
            aria-hidden="true"
            className="border-0 outline-none absolute right-0"
            onClick={onClose}
            title="Close"
          >
            <div className="bg-white w-11 h-11 rounded-full flex justify-center items-center">
              <Close className="w-4 h-4" />
            </div>
          </button>

          {isErrored ? (
            <div className="text-black text-center py-5">
              Whoops! Something went wrong. Please try again.
            </div>
          ) : isInitialized ? (
            <Tab.Group defaultIndex={0}>
              <Tab.List className="inline-flex space-x-1">
                {tabs.map((tab) => (
                  <Tab as={Fragment} key={tab.label}>
                    {({ selected }) => (
                      <button
                        className={twMerge(
                          selected
                            ? 'text-white font-bold bg-gray-800 hover:bg-gray-900'
                            : 'text-gray-900 hover:bg-white',
                          'min-w-32 py-3 px-4 border-0 rounded-full transition-colors leading-6 text-center',
                          'focus:outline-none focus:bg-gray-900 focus-visible:ring focus-visible:ring-opacity-20 focus-visible:ring-gray-500',
                          'hover:no-underline'
                        )}
                      >
                        {tab.label}
                      </button>
                    )}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels>
                {tabs.map((tab) => (
                  <Tab.Panel key={tab.label}>
                    <SimpleSearchResults
                      currentStockItems={tab.data.results}
                      redirectToSignUpLink={null}
                      shouldShowAddToFavorites
                      context={StockItemContext.DRAWER}
                      containsCollapsedStockItems={false}
                      useSubscriptionlessSignUpEnticement={null}
                      searchResultsContainerCSSClasses="grid grid-cols-1 gap-3 md:grid-cols-3 lg:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6"
                    />
                    {tab.data.hasMore && !tab.data.isLoading && (
                      <button
                        type="button"
                        className="border-none text-gray-1000 mx-auto mt-5 flex items-center"
                        onClick={() => loadMore(tab.id)}
                      >
                        View More
                        <ChevronDown className="w-3.5 h-3.6 ml-2 fill-current" />
                      </button>
                    )}
                    {tab.data.isLoading && (
                      <div className="pt-2.5 -mb-2.5">
                        <LoadingIndicator compact />
                      </div>
                    )}
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          ) : (
            <LoadingIndicator />
          )}
        </div>
      </div>
    </div>
  );
};

export default SameShootSameModelSimilarClipsDrawer;

import { AUDIO, getCurrentSite, IMAGE, VIDEO } from '../../../../common/utils';
import AudioStockItemCard from './AudioStockItemCard';
import GraphicStockItemCard from './GraphicStockItemCard';
import VideoStockItemCard from './VideoStockItemCard';

function factory() {
  switch (getCurrentSite()) {
    case 'graphicstock':
      return GraphicStockItemCard;

    case 'videoblocks':
      return VideoStockItemCard;

    case 'audioblocks':
      return AudioStockItemCard;

    default:
      return null;
  }
}

export function stockItemCardForContentClass(contentClass) {
  switch (contentClass) {
    case IMAGE:
      return GraphicStockItemCard;

    case VIDEO:
      return VideoStockItemCard;

    case AUDIO:
      return AudioStockItemCard;

    default:
      return null;
  }
}

const StockItemCard = factory();

export default StockItemCard;

import React from 'react';

export interface CollectionItem {
  name: string;
  url: string;
  thumbnailUrl: string;
}

const CollectionViewItem = ({ name, url, thumbnailUrl }: CollectionItem) => (
  <div className="collection-wrapper w-10/12 lg:w-1/3 xl:w-1/4 flex-shrink-0">
    <img
      className={'collection-image'}
      src={thumbnailUrl}
      alt={name}
      width={290}
      height={185}
    />
    <div className="collection-image-shadow" />
    <div className="collection-text">
      <div className="collection-text-header">{name}</div>
      <a className="btn btn-default collection-text-link" href={url}>
        View Collection
      </a>
    </div>
  </div>
);

export default CollectionViewItem;

import React, { Component } from 'react';

import { LegacyPrimaryButton as PrimaryButton } from '@videoblocks/storywind';

import CollectionView from '../../../../Collections/CollectionView';
import { updateSearchOptionsAndRefresh } from '../../actions/SearchActions';
import SearchOptions from '../../entities/SearchOptions';

import './no-results.less';

interface Props {
  searchTerm: string;
  contentType: string;
  shouldShowSimpleNoResults: boolean;
}

export default class AbstractNoResults extends Component<Props> {
  render() {
    return this.props.shouldShowSimpleNoResults
      ? this.renderSimpleResults()
      : this.renderNoResultsWithCollections();
  }

  renderNoResultsWithCollections() {
    const headerContentType = this.renderHeaderContentType();
    const collectionsContentType = this.renderCollectionsContentType();

    return (
      <div className="noResults">
        <div className="noResults-topSection">
          <h5 className="noResults-topSectionHeader">
            Sorry, we couldn’t find any {headerContentType} that match your
            selected criteria
          </h5>
          <div className="noResults-topSectionText">
            <p>Here are some tips to help you find what you need:</p>
            <ul className="noResults-listText">
              <li>Try a new search term</li>
              <li>Check your spelling</li>
              <li>Clear your filters</li>
            </ul>
          </div>
          <PrimaryButton label="Remove Filters" onClick={this.clearFilters} />
        </div>
        <div className="noResults-collectionsSection">
          <h5 className="noResults-collectionsSectionHeader">
            Try checking out some of our Collections
          </h5>
          <p>
            We handpicked some of the freshest, hottest {collectionsContentType}{' '}
            for you. Spend less time searching, more time creating.
          </p>
          <CollectionView collections={this.collectionViewItems()} />
        </div>
      </div>
    );
  }

  renderSimpleResults() {
    throw new TypeError(
      this.constructor.name + ' must implement renderSimpleResults()'
    );
  }

  collectionViewItems() {
    throw new TypeError(
      this.constructor.name + ' must implement collectionViewItems()'
    );
  }

  renderHeaderContentType() {
    throw new TypeError(
      this.constructor.name + ' must implement renderHeaderContentType()'
    );
  }

  renderCollectionsContentType() {
    throw new TypeError(
      this.constructor.name + ' must implement renderCollectionsContentType()'
    );
  }

  clearFilters = (e) => {
    e.preventDefault();

    let newSearchOptions = SearchOptions.getDefaultSearchOptions();
    newSearchOptions = newSearchOptions.update({
      searchTerm: this.props.searchParams.searchTerm,
    });

    this.props.dispatch(updateSearchOptionsAndRefresh(newSearchOptions));
  };
}

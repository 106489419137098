import React from 'react';
import { connect } from 'react-redux';

import SiteConstants from '../../../../common/SiteConstants/SiteConstants';
import {
  formatDurationSearch,
  prettifyList,
  ucfirst,
} from '../../../../common/utils';
import {
  selectSearchContext,
  selectSearchFilterOptions,
} from '../../selectors/searchSelectors';
import AbstractNoResults from './AbstractNoResults';

class AudioNoResults extends AbstractNoResults {
  renderSimpleResults() {
    return (
      <div className="as-no-results">
        <p id="no-search-results-text">{this.getNoResultsString()}</p>
      </div>
    );
  }

  getNoResultsString() {
    const {
      contentType,
      tempoMax,
      musicGenres,
      musicMoods,
      tempoMin,
      categories,
      searchTerm,
      minDuration,
      maxDuration,
    } = this.props.searchParams;

    let noResponseText = 'Your search for ';

    if (searchTerm) {
      noResponseText += '"' + searchTerm + '"';
    }

    contentType !== 'all'
      ? (noResponseText += ' ' + ucfirst(contentType))
      : (noResponseText += ' ' + 'Audio');

    let checkBoxArray = [];

    const checkList = {
      categories: categories,
      genres: musicGenres,
      moods: musicMoods,
    };

    for (let key in checkList) {
      if (checkList[key].length > 0) {
        checkBoxArray.push(key);
      }
    }

    if (
      (tempoMin && tempoMin > SiteConstants.getInstance().getMinTempo()) ||
      (tempoMax && tempoMax < SiteConstants.getInstance().getMaxTempo())
    ) {
      checkBoxArray.push('tempo');
    }

    noResponseText +=
      prettifyList(' with the specified ', checkBoxArray) +
      formatDurationSearch(minDuration, maxDuration) +
      ' returned no results';

    return noResponseText;
  }

  renderHeaderContentType() {
    return 'audio';
  }

  renderCollectionsContentType() {
    return 'tracks';
  }

  collectionUrl(name) {
    return '/audio/collections/' + name;
  }

  collectionViewItems() {
    return [
      {
        thumbnailUrl:
          'https://d2h53m62qt2m27.cloudfront.net/collection_pages/AB/ziykjrd0lojv1xbnta6q-1564669914947.jpg',
        name: 'Travel',
        url: this.collectionUrl('travel-video'),
      },
      {
        thumbnailUrl:
          'https://d2h53m62qt2m27.cloudfront.net/collection_pages/AB/tiiwrflgyojfnpzzoakg-1571068693948.jpg',
        name: 'Music for Explainer Videos',
        url: this.collectionUrl('music-for-explainer-videos'),
      },
      {
        thumbnailUrl:
          'https://d2h53m62qt2m27.cloudfront.net/collection_pages/AB/rm1eyudrxk3div1iflzc-1564674911761.jpg',
        name: 'Explainer Video',
        url: this.collectionUrl('explainer-video'),
      },
      {
        thumbnailUrl:
          'https://d2h53m62qt2m27.cloudfront.net/collection_pages/AB/r6gr3jgdp17fnmkg6lh5-1564674312903.jpg',
        name: 'Sports and Fitness',
        url: this.collectionUrl('sports-and-fitness-video'),
      },
    ];
  }
}

function mapStateToProps(state, ownProps) {
  const contextFromRedux = selectSearchContext(state);
  const context = (ownProps && ownProps.context) || contextFromRedux;

  return {
    selectedSearchFilterOptions: selectSearchFilterOptions(state),
    shouldShowSimpleNoResults: context !== 'search',
  };
}

export default connect(mapStateToProps)(AudioNoResults);

import React from 'react';

import SiteConstants from '../../../../common/SiteConstants/SiteConstants';

export default function AudioNumResultsViewed({ total }) {
  const formattedTotal =
    total.toLocaleString() +
    (total === SiteConstants.searchResultsLimit() ? '+' : '');

  return (
    <div>{`${formattedTotal} ${total > 1 ? 'results' : 'result'} found`}</div>
  );
}

import React, { Component } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDown } from '@videoblocks/react-icons';
import { Pagination } from '@videoblocks/storywind';

import {
  AUDIOBLOCKS,
  GRAPHICSTOCK,
  VIDEOBLOCKS,
} from '../../../../common/SiteConstants/SiteConstants';
import RegionalSpinner from '../../../../common/components/RegionalSpinner';
import {
  StockItem,
  StockItemContext,
  StockItemFormat,
} from '../../../../common/types/StockItemTypes';
import {
  filterUrlSeoSearchParameters,
  getCurrentSite,
  getPageUrl,
} from '../../../../common/utils';
import { DrawerType, SearchPaginationState } from '../../SearchTypes';
import { DRAWER_DEFAULT_ITEM_COUNT } from '../../actions/SearchActions';
import { AnySearchFilterOptions } from '../../containers/MenuContainerInterfaces';
import Drawer from '../Drawer';
import SameShootSameModelSimilarClipsDrawer from '../SameShootSameModelSimilarClipsDrawer';
import { stockItemCardForContentClass } from '../card/StockItemCard';
import NoResults from '../noResults/NoResults';
import NumResultsViewed from '../numResults/NumResultsViewed';

type Props = {
  currentStockItems: Array<{
    stockItem: StockItem;
    stockItemFormats: StockItemFormat[];
  }>;
  searchLogParameters?: object;
  isLoading?: boolean;
  isLoadingAdditionalResults?: boolean;
  selectedStockItemDetails?: {
    stockItem: StockItem;
    stockItemFormats: StockItemFormat[];
  };
  itemsPerRow?: number;
  goToSearchResultsPage?: (pageNumber: number) => void;
  redirectToSignUpLink: boolean;
  selectedSearchFilterOptions?: AnySearchFilterOptions;
  pagination?: SearchPaginationState;
  showButtons?: boolean;
  shouldShowAddToFavorites: boolean;
  context: StockItemContext;
  stockItemContainerResponsiveClassNames?: string[];
  drawerIsOpen?: boolean;
  drawerType?: DrawerType;
  drawerSelectedItemId?: number;
  containsCollapsedStockItems: boolean;
  shouldShowDownloadButton?: boolean;
  updateDrawerNumberItemsVisible?: (
    totalNumberOfCollapsedStockItemsToShow: number
  ) => void;
  statusCode?: number;
  draggableCards?: boolean;
  dropOntoHandler?: () => void;
  countryCode?: string;
  useSubscriptionlessSignUpEnticement: boolean;
  hideSearchResults?: boolean;
  searchResultsContainerCSSClasses?: string;
  searchResultsCssClasses?: string;
  useNewDrawerStyle?: boolean;
  onDrawerClose?(): void;
};

type State = {
  totalNumberOfCollapsedStockItemsToShow: number;
  needsLayoutShift?: boolean;
};

class SimpleSearchResults extends Component<Props, State> {
  MAX_SEARCH_PAGE_LIMIT = 99;

  searchResultsContainerRef: HTMLDivElement;

  constructor(props) {
    super(props);
    this.state = {
      totalNumberOfCollapsedStockItemsToShow: Math.min(
        this.props.currentStockItems.length,
        DRAWER_DEFAULT_ITEM_COUNT
      ),
    };

    if (this.props.updateDrawerNumberItemsVisible) {
      this.props.updateDrawerNumberItemsVisible(
        this.state.totalNumberOfCollapsedStockItemsToShow
      );
    }
  }

  render() {
    const {
      isLoading,
      isLoadingAdditionalResults,
      statusCode,
      pagination,
      hideSearchResults,
    } = this.props;

    const isSearching = isLoading && !isLoadingAdditionalResults;

    if (isSearching || isLoadingAdditionalResults) {
      return this.renderLoadingSpinner();
    }
    if (statusCode && statusCode !== 200) {
      return this.renderApiError();
    }
    if (hideSearchResults || pagination?.totalResults <= 0) {
      return this.renderNoResults();
    }
    return this.renderSearchResults();
  }

  renderLoadingSpinner() {
    return (
      <div className="spinner-container justify-center">
        <RegionalSpinner
          className="search-results-spinner"
          countryCode={this.props.countryCode}
        />
      </div>
    );
  }

  renderNoResults() {
    return <NoResults searchParams={this.props.selectedSearchFilterOptions} />;
  }

  renderApiError() {
    const httpStatusCode = this.props.statusCode;
    let errMessage =
      'Oops, we’re having trouble understanding your search. Please try something simpler.';

    if (httpStatusCode >= 500) {
      // 5XX error
      errMessage =
        'Well this is embarrassing… our search engine blew a fuse. Please try your search again later.';
    } else if (httpStatusCode === 408) {
      errMessage =
        'Well this is embarrassing… our search engine blew a fuse. Please try your search again or refresh the page.';
    }

    return (
      <div className="as-no-results">
        <p>{errMessage}</p>
      </div>
    );
  }

  renderSearchResults() {
    const { context } = this.props;

    return (
      <section
        className={twMerge(this.getSearchResultsCSSClasses())}
        ref={(ref) => {
          (this.searchResultsContainerRef as HTMLElement) = ref;
        }}
      >
        <div
          id="as-search-results"
          className={this.props.useNewDrawerStyle ? 'relative' : ''}
        >
          {this.state.needsLayoutShift ? (
            this.renderLoadingSpinner()
          ) : (
            <>
              <div
                className={twMerge(this.getSearchResultsContainerCSSClasses())}
                style={this.getSearchResultsListStyles()}
              >
                {this.renderStockItems()}
              </div>
              {this.renderViewMoreButton()}
            </>
          )}
        </div>
        {context !== StockItemContext.DRAWER &&
          context !== StockItemContext.SIMILAR_CONTENT && (
            <div className="clearfix">&nbsp;</div>
          )}
        {this.props.pagination?.totalResults > 0 && this.renderPagination()}
      </section>
    );
  }

  renderPagination = () => {
    const {
      isLoading,
      isLoadingAdditionalResults,
      pagination,
      countryCode,
      selectedSearchFilterOptions,
    } = this.props;

    const { resultsPerPage, totalResults, maxPageNumber, currentPageNumber } =
      pagination;
    const showLoadingIndicator = isLoading && isLoadingAdditionalResults;

    const totalPages = Math.min(
      this.props.pagination.maxPageNumber,
      this.MAX_SEARCH_PAGE_LIMIT
    );

    // In the case that a page is access through query params, don't show pagination
    // e.g., ?page=5 when only 1 page exists
    if (currentPageNumber > maxPageNumber) {
      return null;
    }

    if (showLoadingIndicator) {
      return (
        <div className="search-pagination text-center">
          <RegionalSpinner
            className="pagination-spinner"
            width="80px"
            countryCode={countryCode}
          />
        </div>
      );
    }

    const getPageHref = (pageNumber) => {
      const isFirstPage = pageNumber === 1;
      const url = filterUrlSeoSearchParameters(
        getPageUrl(isFirstPage ? undefined : pageNumber)
      );
      return url;
    };

    return (
      <div className="search-pagination text-center">
        <NumResultsViewed
          total={
            selectedSearchFilterOptions.similarTo &&
            totalResults > resultsPerPage
              ? resultsPerPage
              : totalResults
          }
        />
        <Pagination
          activePage={this.props.pagination.currentPageNumber}
          onPageSelect={this.props.goToSearchResultsPage}
          prefix="search-results"
          totalPages={totalPages}
          getHref={getPageHref}
          terminalButtons={2}
          maxButtons={9}
        />
      </div>
    );
  };

  showLastStockItemAsLoadMoreButton(stockItems, context) {
    stockItems = stockItems.slice(
      0,
      this.state.totalNumberOfCollapsedStockItemsToShow
    );
    if (this.props.useNewDrawerStyle) {
      return stockItems;
    }
    if (
      this.props.currentStockItems.length >
      this.state.totalNumberOfCollapsedStockItemsToShow
    ) {
      const index = this.state.totalNumberOfCollapsedStockItemsToShow - 1;
      stockItems[index] = this.renderStockItemCard(
        this.props.currentStockItems[index],
        false,
        context,
        this.getStockItemCardContext(context, index + 1),
        true
      );
    }

    return stockItems;
  }

  handleLoadingMoreCollapsedStockItems = () => {
    if (getCurrentSite() !== GRAPHICSTOCK && getCurrentSite() !== VIDEOBLOCKS) {
      return () => {
        /* no-op */
      };
    }

    const numberOfStockItems = this.props.currentStockItems.length;
    if (
      this.state.totalNumberOfCollapsedStockItemsToShow < numberOfStockItems
    ) {
      const numberOfCollapsedStockItemsToShow = Math.min(
        this.props.currentStockItems.length,
        this.state.totalNumberOfCollapsedStockItemsToShow +
          DRAWER_DEFAULT_ITEM_COUNT
      );
      this.setState({
        totalNumberOfCollapsedStockItemsToShow:
          numberOfCollapsedStockItemsToShow,
      });

      this.props.updateDrawerNumberItemsVisible(
        numberOfCollapsedStockItemsToShow
      );
    }
  };

  renderStockItems() {
    const {
      currentStockItems,
      drawerIsOpen,
      drawerSelectedItemId,
      itemsPerRow,
      pagination,
      context,
    } = this.props;

    let stockItems = currentStockItems.map((stockItemDetails, index) => {
      let hideOnThreeColumnLayout = false;
      if (pagination) {
        const { currentPageNumber, maxPageNumber } = pagination;

        hideOnThreeColumnLayout =
          currentPageNumber === maxPageNumber
            ? false
            : this.shouldHideOnThreeColumnLayout(
                index,
                currentStockItems.length
              );
      }

      return this.renderStockItemCard(
        stockItemDetails,
        hideOnThreeColumnLayout,
        context,
        this.getStockItemCardContext(context, index + 1),
        false,
        index > 18 // Disable lazy-loading for the first 18 results. Prevents flashing the placeholder before the actual image since above the fold on large desktop screens.
      );
    });

    if (this.props.containsCollapsedStockItems) {
      stockItems = this.showLastStockItemAsLoadMoreButton(stockItems, context);
    }

    // If the drawer is closed, don't bother splicing it into stockItems.
    if (!drawerIsOpen || context !== StockItemContext.SEARCH) {
      return stockItems;
    }

    const selectedItemIndex = currentStockItems.findIndex(
      (wrapper) => wrapper.stockItem.id === drawerSelectedItemId
    );
    const selectedItemColumn = selectedItemIndex % itemsPerRow;
    const drawerIndex = this.calculateDrawerIndex(
      selectedItemIndex,
      itemsPerRow
    );
    const drawer = this.buildDrawer(drawerSelectedItemId, selectedItemColumn);
    stockItems.splice(drawerIndex, 0, drawer);

    return stockItems;
  }

  renderViewMoreButton() {
    if (
      this.props.containsCollapsedStockItems &&
      this.props.currentStockItems.length >
        this.state.totalNumberOfCollapsedStockItemsToShow &&
      this.props.useNewDrawerStyle
    )
      return (
        <button
          type="button"
          className="border-none text-gray-1000 mx-auto mt-5 flex items-center"
          onClick={() => {
            this.handleLoadingMoreCollapsedStockItems();
          }}
        >
          View More
          <ChevronDown className="w-3.5 h-3.6 ml-2 fill-current" />
        </button>
      );
  }

  shouldHideOnThreeColumnLayout(index, totalLen) {
    const partialRowNumber = Math.floor(totalLen / 3);
    const firstIdxOnPartialRow = partialRowNumber * 3;

    return index >= firstIdxOnPartialRow;
  }

  renderStockItemCard(
    stockItemDetails, // todo: { stockItem: StockItem; stockItemFormats: StockItemFormat[] }?
    hideOnThreeColumnLayout: boolean,
    context: StockItemContext,
    stockItemCardContext: { title: string; position: number },
    isCollapsedStockItemLoadMoreCard: boolean,
    lazyLoad?: boolean
  ) {
    const Card = stockItemCardForContentClass(
      stockItemDetails.stockItem.contentClass
    );

    return (
      <Card
        key={stockItemDetails.stockItem.id}
        searchLogParameters={this.props.searchLogParameters}
        isSelected={
          this.props.selectedStockItemDetails &&
          stockItemDetails.stockItem.id ===
            this.props.selectedStockItemDetails.stockItem.id
        }
        redirectToSignUpLink={this.props.redirectToSignUpLink}
        hideOnThreeColumnLayout={hideOnThreeColumnLayout}
        style={this.getStockItemCardStyles(stockItemDetails)}
        classNames={this.getStockItemCardCSSClasses(stockItemDetails)}
        imgClassNames={this.getStockItemCardImageCSSClasses(stockItemDetails)}
        stockItem={stockItemDetails.stockItem}
        stockItemFormats={stockItemDetails?.stockItemFormats}
        minimize={this.props.showButtons}
        shouldShowAddToFavorites={this.props.shouldShowAddToFavorites}
        shouldShowSelectCheckbox={stockItemDetails.shouldShowSelectCheckbox}
        checked={stockItemDetails.checked}
        context={context || this.props.context}
        stockItemCardContext={stockItemCardContext}
        isFavorite={stockItemDetails.isFavorite}
        page={stockItemDetails.page}
        isSavingFavorite={stockItemDetails.isSavingFavorite}
        containerResponsiveClassNames={
          this.props.stockItemContainerResponsiveClassNames
        }
        shouldStockItemHaveNewFlag={
          stockItemDetails.stockItem.shouldStockItemHaveNewFlag
        }
        shouldStockItemHaveStaffPickFlag={
          stockItemDetails.stockItem.shouldStockItemHaveStaffPickFlag
        }
        showFavoritesTooltip={stockItemDetails.showFavoritesTooltip}
        handleLoadingMoreCollapsedStockItems={
          this.handleLoadingMoreCollapsedStockItems
        }
        shouldShowDownloadButton={this.props.shouldShowDownloadButton}
        draggableCard={this.props.draggableCards}
        dropOntoHandler={this.props.dropOntoHandler}
        useSubscriptionlessSignUpEnticement={
          this.props.useSubscriptionlessSignUpEnticement
        }
        lazyLoad={lazyLoad}
        topTags={stockItemDetails.topTags}
        stockItemArtists={stockItemDetails.stockItemArtists}
        moods={stockItemDetails.moods}
        genres={stockItemDetails.genres}
        isCollapsedStockItemLoadMoreCard={isCollapsedStockItemLoadMoreCard}
      />
    );
  }

  getSearchResultsCSSClasses() {
    const cssClasses = ['stock-item-group-wrapper', 'card-design', 'mt-5'];
    const { context } = this.props;

    if (context === StockItemContext.CONTENT_TYPE) {
      cssClasses.push('media-type', 'mt-0');
    }

    if (context === StockItemContext.DRAWER && !this.props.useNewDrawerStyle) {
      cssClasses.push('-ml-2', '-mr-2');
    }

    return [twMerge(...cssClasses, this.props.searchResultsCssClasses)];
  }

  getSearchResultsListStyles() {
    return {};
  }

  getSearchResultsContainerCSSClasses(): string[] {
    if (this.props.searchResultsContainerCSSClasses) {
      return [this.props.searchResultsContainerCSSClasses];
    }

    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getStockItemCardCSSClasses(stockItemDetails?: object) {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getStockItemCardStyles(stockItemDetails?: object) {
    return {};
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getStockItemCardImageCSSClasses(stockItemDetails?: object) {
    return [];
  }

  getDrawerStyles() {
    return {};
  }

  getStockItemCardContext(title: string, position: number) {
    return {
      title,
      position,
    };
  }

  calculateDrawerIndex(selectedItemIndex, currentColumnCount) {
    const selectedItemRow = Math.floor(selectedItemIndex / currentColumnCount);
    return (selectedItemRow + 1) * currentColumnCount;
  }

  calculateIndicatorOffset() {
    const stockItemCard: HTMLElement = document.querySelector(
      `[data-stock-id="${this.props.drawerSelectedItemId}"]`
    );

    if (!this.props.useNewDrawerStyle) {
      return stockItemCard.offsetLeft - 16;
    }

    const offset = stockItemCard.offsetLeft + stockItemCard.offsetWidth / 2;

    return offset;
  }

  static getPointerClasses(selectedItemColumn, classes) {
    const offsetSm = 4 * selectedItemColumn;
    const offsetMd = 4 * selectedItemColumn;
    const offsetLg = 3 * selectedItemColumn;

    return [
      'col-xs-offset-1',
      `col-sm-offset-${offsetSm}`,
      `col-md-offset-${offsetMd}`,
      `col-lg-offset-${offsetLg}`,
      'col-xs-10',
      'col-sm-4',
      'col-md-4',
      'col-lg-3',
      'pointer',
      ...classes,
    ];
  }

  buildDrawer(drawerSelectedItemIndex: number, selectedItemColumn: number) {
    const indicatorClasses = SimpleSearchResults.getPointerClasses(
      selectedItemColumn,
      this.props.useNewDrawerStyle ? ['justify-start'] : []
    ).join(' ');

    const itemLimit = getCurrentSite() === AUDIOBLOCKS ? 10 : null;

    const selectedItemIndicatorOffset = this.calculateIndicatorOffset();

    return this.props.drawerType ===
      DrawerType.SAME_SHOOT_SAME_MODEL_SIMILAR_CLIPS ? (
      <SameShootSameModelSimilarClipsDrawer
        stockItemId={drawerSelectedItemIndex}
        onClose={this.props.onDrawerClose}
      />
    ) : (
      <Drawer
        indicatorClasses={indicatorClasses}
        key={`drawer-for-${drawerSelectedItemIndex}`}
        selectedItemId={drawerSelectedItemIndex}
        selectedItemIndicatorOffset={selectedItemIndicatorOffset}
        itemLimit={itemLimit}
      />
    );
  }
}

export default SimpleSearchResults;

import React, { Component } from 'react';

import CollectionViewItem, { CollectionItem } from './CollectionViewItem';

interface Props {
  collections: CollectionItem[];
}

export default class CollectionView extends Component<Props> {
  render() {
    return (
      <div id="searchCollections">
        <div className="collections-container flex flex-wrap justify-center">
          {this.renderCollectionViews()}
        </div>
      </div>
    );
  }

  renderCollectionViews() {
    return this.props.collections.map((collection, index) => {
      return (
        <CollectionViewItem
          thumbnailUrl={collection.thumbnailUrl}
          url={collection.url}
          name={collection.name}
          key={index}
        />
      );
    });
  }
}

import React from 'react';
import { connect } from 'react-redux';

import { formatDurationSearch, ucfirst } from '../../../../common/utils';
import { categoryTypes } from '../../entities/VideoSearchFilterOptions';
import {
  selectSearchContext,
  selectSearchFilterOptions,
} from '../../selectors/searchSelectors';
import AbstractNoResults from './AbstractNoResults';

class VideoNoResults extends AbstractNoResults {
  renderSimpleResults() {
    return (
      <div className="as-no-results">
        <p id="no-search-results-text">{this.getNoResultsString()}</p>
      </div>
    );
  }

  getNoResultsString() {
    const {
      contentType,
      videoQuality,
      searchTerm,
      categories,
      minDuration,
      maxDuration,
    } = this.props.searchParams;

    let noResponseText = 'Your search for ' + videoQuality;
    if (searchTerm) {
      noResponseText += ' "' + searchTerm + '" in';
    }

    contentType !== 'all'
      ? (noResponseText += ' ' + ucfirst(contentType))
      : (noResponseText += ' Videos');

    if (categories && categories.length > 0) {
      const categoryText = categoryTypes
        .filter((category) => category.value && categories === category.value)
        .map((category) => category.label)
        .join(', ');
      noResponseText += ' in ' + categoryText;
    }

    if (minDuration && maxDuration) {
      noResponseText += formatDurationSearch(minDuration, maxDuration);
    }

    return noResponseText + ' returned no results.';
  }

  renderHeaderContentType() {
    return 'videos';
  }

  renderCollectionsContentType() {
    return 'videos';
  }

  collectionUrl(name) {
    return '/video/collections/' + name;
  }

  collectionViewItems() {
    return [
      {
        thumbnailUrl: `${__ASSETS_COMMON_IMAGES_URL__}/collections/bio-video-monica.png`,
        name: 'Re:Stock - Diverse Stock Footage',
        url: '/diverse-stock-footage',
      },
      {
        thumbnailUrl:
          'https://d2h53m62qt2m27.cloudfront.net/collection_pages/VB/nat-doc-directory.jpg',
        name: 'Nature Documentary',
        url: this.collectionUrl('nature-documentary-footage'),
      },
      {
        thumbnailUrl:
          'https://d1ow200m9i3wyh.cloudfront.net/collections//tmp/77e7be521fe2d361cd2e5ca530645bbcfba1a89cb9b04d28ddefd968cb24e777_dir.jpg',
        name: 'Space Exploration',
        url: this.collectionUrl('space-footage-exploration'),
      },
      {
        thumbnailUrl:
          'https://d2h53m62qt2m27.cloudfront.net/collection_pages/VB/aerials_thumb.jpg',
        name: 'Amazing Aerials',
        url: this.collectionUrl('premium-aerial-footage'),
      },
      {
        thumbnailUrl:
          'https://d1ow200m9i3wyh.cloudfront.net/collections//tmp/21a22701fff5588a46538be78c438f193e5c0a339575c78d01380bdbaf24c2cf_dir.png',
        name: 'YouTube Templates',
        url: this.collectionUrl('youtube-intros-outros'),
      },
      {
        thumbnailUrl:
          'https://d1ow200m9i3wyh.cloudfront.net/collections//tmp/add71fbc87e813b815bbb8e9ea88ce67600b65670713917b4ecbdfc7eec2c0a9_dir.jpg',
        name: 'Landmark Footage',
        url: this.collectionUrl('landmark-footage'),
      },
      {
        thumbnailUrl:
          'https://d2h53m62qt2m27.cloudfront.net/collection_pages/VB/timewarp-vb-directory.jpg',
        name: 'Timewarp Footage',
        url: this.collectionUrl('timewarp-footage'),
      },
      {
        thumbnailUrl:
          'https://d1ow200m9i3wyh.cloudfront.net/collections//tmp/2bb09ac82effaf16a9c72dda9fe04c34a7aec6ce319965a4846dbfdeb75b93a1_dir.jpg',
        name: 'Creator to Creator',
        url: this.collectionUrl('creator-to-creator'),
      },
    ];
  }
}

function mapStateToProps(state, ownProps) {
  const contextFromRedux = selectSearchContext(state);
  const context = (ownProps && ownProps.context) || contextFromRedux;

  return {
    selectedSearchFilterOptions: selectSearchFilterOptions(state),
    shouldShowSimpleNoResults: context !== 'search',
  };
}

export default connect(mapStateToProps)(VideoNoResults);

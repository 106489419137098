import React from 'react';
import { connect } from 'react-redux';

import { prettifyList, ucfirst } from '../../../../common/utils';
import { categories as categoryOptions } from '../../entities/ImageSearchFilterOptions';
import {
  selectSearchContext,
  selectSearchFilterOptions,
} from '../../selectors/searchSelectors';
import AbstractNoResults from './AbstractNoResults';

class GraphicNoResults extends AbstractNoResults {
  renderSimpleResults() {
    return (
      <div className="as-no-results">
        <p id="no-search-results-text">{this.getNoResultsString()}</p>
      </div>
    );
  }

  getNoResultsString() {
    const {
      contentType,
      orientation,
      categories,
      color,
      searchTerm,
      hasTransparency,
    } = this.props.searchParams;

    let noResponseText = 'Your search for ';

    if (orientation !== 'all') {
      noResponseText += orientation + ' ';
    }

    if (searchTerm) {
      noResponseText += ' "' + searchTerm + '" ';
    }

    contentType !== 'all'
      ? (noResponseText += ' ' + ucfirst(contentType))
      : (noResponseText += ' Images');

    if (categories && categories.length > 0) {
      const categoryText = categoryOptions
        .filter(
          (category) => category.value && categories.includes(category.value)
        )
        .filter((category) => category.contentType === contentType)
        .map((category) => category.label)
        .join(', ');
      noResponseText += ' in ' + categoryText;
    }

    let specs = [];
    const checkList = { color: color, transparency: hasTransparency };

    for (let key in checkList) {
      if (checkList[key] && checkList[key].length > 0) {
        specs.push(key);
      }
    }

    noResponseText +=
      prettifyList(' with the specified ', specs) + ' returned no results.';

    return noResponseText;
  }

  renderHeaderContentType() {
    return 'images';
  }

  renderCollectionsContentType() {
    return 'images';
  }

  collectionViewItems() {
    return [
      {
        thumbnailUrl:
          'https://d1ow200m9i3wyh.cloudfront.net/collections//tmp/7037b43905f01717284cf96591f35d3877d8610f3a1cdde3e817a6bca5a714fa_dir.jpg',
        name: 'Infographic Templates',
        url: '/images/collections/infographic-templates',
      },
      {
        thumbnailUrl:
          'https://d1ow200m9i3wyh.cloudfront.net/collections//tmp/bf22fd37710d51a307d6ff4f5613eb623fded37069465894d145cb3e98067f00_dir.jpg',
        name: 'Event Poster Templates',
        url: '/images/collections/event-poster-templates',
      },
      {
        thumbnailUrl:
          'https://d1ow200m9i3wyh.cloudfront.net/collections//tmp/61f9475eb5711ede21badc4bfd7bc9e4510501036325a81046654126c9968401_dir.png',
        name: 'Mockup Templates',
        url: '/images/collections/design-psd-mockups',
      },
      {
        thumbnailUrl:
          'https://d1ow200m9i3wyh.cloudfront.net/collections//tmp/e17512b30ddac3b68783394d26f84098075816ea4bd2963a6a630d5b129127dd_dir.jpg',
        name: 'Creative Backgrounds',
        url: '/images/collections/background-images',
      },
      {
        thumbnailUrl:
          'https://d1ow200m9i3wyh.cloudfront.net/collections//tmp/ba9e51b2136427848e335a35c3b1175e0d271e077ec2b711b65f951191e98a68_dir.jpg',
        name: 'Infographic Icons',
        url: '/images/collections/infographic-vector-icons',
      },
      {
        thumbnailUrl:
          'https://d1ow200m9i3wyh.cloudfront.net/collections//tmp/a6c5a8ba1fb117770e3a1eb6ed9cd23eec266d1467f450da9e1a57e25e4b691f_dir.jpg',
        name: 'Creative Edge',
        url: '/images/collections/creative-edge-colorful-images',
      },
      {
        thumbnailUrl:
          'https://d1ow200m9i3wyh.cloudfront.net/collections//tmp/b5223beec0dc4667be96f25eb7d79764aba393634e251d86496846143e2e2da1_dir.jpg',
        name: 'The Future of Creativity',
        url: '/images/collections/future-of-creativity',
      },
      {
        thumbnailUrl:
          'https://d1ow200m9i3wyh.cloudfront.net/collections//tmp/61a4ef6170c3987bdfef7c619d395a77193f9d4f2bed4d85846d94c49566187f_dir.jpg',
        name: 'Get Cozy',
        url: '/images/collections/cozy-photos',
      },
    ];
  }
}

function mapStateToProps(state, ownProps) {
  const contextFromRedux = selectSearchContext(state);
  const context = (ownProps && ownProps.context) || contextFromRedux;

  return {
    selectedSearchFilterOptions: selectSearchFilterOptions(state),
    shouldShowSimpleNoResults: context !== 'search',
  };
}

export default connect(mapStateToProps)(GraphicNoResults);

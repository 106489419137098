import React from 'react';

import { getCurrentSite } from '../../../../common/utils';
import AudioNumResultsViewed from './AudioNumResultsViewed';

export default function NumResultsViewed({ total }) {
  if (getCurrentSite() === 'audioblocks') {
    return <AudioNumResultsViewed total={total} />;
  }
  return null;
}

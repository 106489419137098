import React from 'react';

import { LegacySeasonalSpinner as SeasonalSpinner } from '@videoblocks/storywind';

export default function RegionalSpinner(props: {
  countryCode?: string;
  [attr: string]: any;
}) {
  const { countryCode } = props;
  const useSeasonal = countryCode?.toUpperCase() === 'US';

  return <SeasonalSpinner {...props} useSeasonal={useSeasonal} />;
}
